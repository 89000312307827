import './Project.css';

interface ProjectParams {
  imageUrl: string;
  title: string;
  description: string;
  url: string;
}

function Project(params: ProjectParams) {
  return (
    <div className='project text-center'>
      <img
        src={params.imageUrl}
        alt='logo'
        width='80vh'
        style={{
          borderRadius: '10px',
        }}
      />
      <h4
        style={{
          marginTop: '10px',
        }}
      >
        {params.title}
      </h4>
      <p
        style={{
          color: '#B3B3B3',
        }}
      >
        {params.description}
      </p>
      <a className='btn btn-secondary' href={params.url}>
        View
      </a>
    </div>
  );
}

export default Project;
