import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './components/NavBar';
import { FaDiscord } from 'react-icons/fa';
import { AiFillGithub } from 'react-icons/ai';
import Project from './components/Project';

function App() {
  return (
    <div
      className='App'
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      <div className='st stars'></div>
      <div className='st stars2'></div>
      <div className='st stars3'></div>
      <section
        className='banner d-flex align-items-center justify-content-center'
        style={{
          backgroundColor: '#090b17',
          height: '95vh',
        }}
        id='banner'
      >
        <NavBar />
        <div className='container d-flex text-white row text-center justify-content-center'>
          <h1 style={{ fontSize: '4em' }}>Hi, I'm Punz</h1>
          <h5 style={{ fontSize: '1.5em', width: '25em', color: '#B8B8B8' }}>
            Self-taught developer, experienced in programming discord bots and
            attracted by web development
          </h5>
          <div className='redes'>
            <FaDiscord
              className='icon'
              style={{ fontSize: '25px', marginRight: '5px', opacity: 0.9 }}
              onClick={() =>
                openUrl('https://discord.com/users/796912511898222656')
              }
            ></FaDiscord>
            <AiFillGithub
              className='icon'
              style={{ fontSize: '25px', marginLeft: '5px', opacity: 0.9 }}
              onClick={() => openUrl('https://github.com/polarsito')}
            ></AiFillGithub>
          </div>
        </div>
      </section>
      <section
        className='about text-white container'
        style={{
          marginBottom: '75px',
        }}
        id='about'
      >
        <h2
          style={{
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          About me
        </h2>
        <hr
          style={{
            width: '15em',
            margin: 'auto',
          }}
        />
        <p
          style={{
            maxWidth: '35em',
            fontSize: '20px',
            textAlign: 'center',
            margin: 'auto',
            paddingTop: '10px',
            color: '#B3B3B3',
          }}
        >
          I started my programming career at 12 years old, developing Discord
          bots in Node.js. To date, I'm 14 years old, I'm from Argentina and I'm
          currently studying. At the moment, I'm acquiring and improving my
          knowledge in languages and frameworks such as TypeScript, Next.js and
          others.
        </p>
      </section>
      <section
        className='skills text-white container'
        style={{
          textAlign: 'center',
          position: 'relative',
          marginBottom: '75px',
        }}
        id='skills'
      >
        <h2>Skills</h2>
        <hr
          style={{
            width: '15em',
            margin: 'auto',
          }}
        />
        <p
          style={{
            fontSize: '20px',
            paddingTop: '10px',
            color: '#B3B3B3',
          }}
        >
          These are my skills
        </p>
        <div className='container d-flex flex-wrap justify-content-center align-items-center gap-2'>
          <img
            src='https://skillicons.dev/icons?i=html&theme=dark'
            alt='HTML'
            id='html'
          />
          <img
            src='https://skillicons.dev/icons?i=css&theme=dark'
            alt='CSS'
            id='css'
          />
          <img
            src='https://skillicons.dev/icons?i=js&theme=dark'
            alt='JavaScript'
            className='js'
            aria-describedby='hola'
          />
          <img
            src='https://skillicons.dev/icons?i=ts&theme=dark'
            alt='TypeScript'
            id='ts'
          />
          <img
            src='https://skillicons.dev/icons?i=nodejs&theme=dark'
            alt='NodeJS'
            id='nodejs'
          />
          <img
            src='https://skillicons.dev/icons?i=sass&theme=dark'
            alt='SASS'
            id='sass'
          />
          <img
            src='https://skillicons.dev/icons?i=bootstrap&theme=dark'
            alt='Bootstrap'
            id='bootstrap'
          />
          <img
            src='https://skillicons.dev/icons?i=tailwindcss&theme=dark'
            alt='TailwindCSS'
            id='tailwindcss'
          />
          <img
            src='https://skillicons.dev/icons?i=react&theme=dark'
            alt='React'
            id='react'
          />
          <img
            src='https://skillicons.dev/icons?i=lua&theme=dark'
            alt='Lua'
            id='lua'
          />
          <img
            src='https://skillicons.dev/icons?i=mongodb&theme=dark'
            alt='MongoDB'
            id='mongodb'
          />
          <img
            src='https://skillicons.dev/icons?i=git&theme=dark'
            alt='Git'
            id='git'
          />
        </div>
      </section>
      <section
        className='projects container text-white text-center'
        id='projects'
      >
        <h2>Projects</h2>
        <hr
          style={{
            width: '15em',
            margin: 'auto',
          }}
        />
        <p
          style={{
            fontSize: '20px',
            paddingTop: '10px',
            color: '#B3B3B3',
          }}
        >
          These are my active and pending projects
        </p>
        <div
          className='projects_body d-flex justify-content-center align-items-center gap-5 flex-wrap container'
          style={{
            marginTop: '50px',
            maxWidth: '60vw',
            marginBottom: '50px',
          }}
        >
          <Project
            imageUrl='https://imgur.com/6IQHlJk.png'
            title='Mind Shop'
            description='Discord Bots shop'
            url='https://discord.gg/pgUrm2Rkzs'
          />
        </div>
      </section>
    </div>
  );
}

function openUrl(url: string) {
  window.open(url);
}

export default App;
