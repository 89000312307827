import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './NavBar.css';
import logo from '../img/pardo.svg';
import { BsFillSunFill } from 'react-icons/bs';
import { Link } from 'react-scroll';

function NavBar() {
  return (
    <nav
      className='NavBar w-100 d-flex justify-content-around align-items-center'
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        marginTop: '15px',
      }}
    >
      <img
        src={logo}
        alt='logo'
        style={{
          width: '2em',
          height: '2em',
          borderRadius: '25%',
        }}
      />
      <ul
        className='nav__links d-flex gap-5'
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <li className='nav__link'>
          <Link
            className='link'
            to='about'
            smooth={true}
            duration={500}
            offset={-200}
          >
            About
          </Link>
        </li>
        <li className='nav__link'>
          <Link
            className='link'
            to='skills'
            smooth={true}
            duration={500}
            offset={-300}
          >
            Skills
          </Link>
        </li>
        <li className='nav__link'>
          <Link className='link' to='projects' smooth={true} duration={500}>
            Projects
          </Link>
        </li>
      </ul>
      <BsFillSunFill
        className='sun'
        style={{
          backgroundColor: 'rgba(255, 255, 255, .1)',
          color: '#fff',
          padding: '6px',
          fontSize: '34px',
          borderRadius: '5px',
        }}
      ></BsFillSunFill>
      <div className='dropdown'>
        <button
          className='btn btn-secondary dropdown-toggle'
          style={{
            backgroundColor: 'rgba(255, 255, 255, .1)',
            borderRadius: '5px',
            color: '#fff',
            border: 'none',
          }}
          type='button'
          aria-expanded='false'
          data-bs-toggle='dropdown'
        >
          ☰
        </button>
        <ul className='dropdown-menu'>
          <li>
            <Link
              className='dropdown-item'
              to='about'
              smooth={true}
              duration={500}
              offset={-200}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              className='dropdown-item'
              to='skills'
              smooth={true}
              duration={500}
              offset={-300}
            >
              Skills
            </Link>
          </li>
          <li>
            <Link
              className='dropdown-item'
              to='projects'
              smooth={true}
              duration={500}
            >
              Projects
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
